import React, {useState, useEffect} from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import {Link} from 'react-router-dom';



function OficinaViva() {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
//     //data for cards

    const cards = [
        {
            title: 'Babau',
            text: 'Fantiche',
            image: '/images/cards/cordel.jpeg',
            URL: '/oficina-viva/babau'
        },
        {
            title: 'Cordel',
            text: ' Some quick example text to build on the card title and make up th bulk of the cards content. Some quick example text to build on the card title and make up th bulk of the cards content.Some quick example text to build on the card title and make up th bulk of the cards content.Some quick example text to build on the card title and make up th bulk of the cards content.',
            image: './cordel-frente.jpeg',
            URL: '/oficina-viva/cordel'
        },
        {
            title: 'Repente',
            text: ' Some quick example text to build on the card title and make up th bulk of the cards content.',
            image: './cordel-frente.jpeg',
            URL: '/oficina-viva/repente'
        },
        {
            title: 'Artes Visuais',
            text: ' Some quick example text to build on the card title and make up th bulk of the cards content.',
            image: './cordel-frente.jpeg',
            URL: '/oficina-viva/artes-visuais'
        }
    ]
    
    const [isExpanded, setIsExpanded] = useState(false);
    

    const cardComponent = cards.map((card, i) => {
        const text = isExpanded ? card.text : (card.text.length > 120) ? `${card.text.substring(0, 120)}...`:`${card.text.substring(0, 120)}`;

        const plus = isExpanded ? null : (card.text.length<120) ? null : <span>&nbsp;<i class="expand-icon fa-solid fa-chevron-down"></i></span>;
        
        const minus = isExpanded ? (card.text.length < 120) ? null : <span>&nbsp;<i class="expand-icon fa-solid fa-chevron-up"></i></span> : null;
 
        return( 
            <Card key={i} className = "col-11 col-md-6 col-lg-2 mb-4 m-4" >
            <Card.Img variant="top" src={card.image} style ={{marginTop: '10px'}}/>
            <Card.Body>
            <Card.Title>{card.title}</Card.Title>
            <Card.Text style={{ marginBottom: '50px' }}>
                {text}            
                <p style={{display: 'inline'}} onClick={() => setIsExpanded(!isExpanded)}>{minus}{plus}</p>     
            </Card.Text>
            <Button variant="primary" as={Link} to = {card.URL} style={{width: "auto", margin: "0 auto", display: "flex", justifyContent: "center", backgroundColor: "rgb(240, 238, 225)", fontWeight: "600", position: 'absolute', bottom: '20px', right: '30px', marginTop: '20px', border: '1px solid black', color: 'black'}}>Saiba mais</Button>
            </Card.Body>
            </Card>
            );
    });

  return (
    <section className = "py-4 container adjustedMargin" >
    <div className= "row justify-content-center">
        <h1 style={{display: 'flex', justifyContent: 'center', alignItems: 'center', fontFamily: 'Xilosa'}}>Oficina Viva</h1>
        {cardComponent}
    </div>
    </section>
);
}

export default OficinaViva;
