import React, {useEffect} from 'react';
import Carousel from 'better-react-carousel'


// Simulate a back button click
function goBack() {
    window.history.back();
}



const ItapecuruMirim2024 = (  ) => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

     const poem = {
      fontFamily: '"Palatino Linotype", "Book Antiqua", Palatino, serif',
      fontStyle: 'italic',
      fontSize: '1.2em',
      lineHeight: '1.6',
      textAlign: 'center',
      marginTop: '10px'
     }

    
    return (
        <div>


            <h1 className="adjustedMargin" style={{textAlign:'center', paddingTop: '5vh'}}>Oficina Sarau e Exposição</h1>
            <p style={{margin: '0 50px'}}>
            A expedição Encanta Cordel levou suas oficinas para a Escola Luís Gomes e o sarau e a exposição para a Biblioteca Municipal de Itapecuru Mirim.</p>
            
              <div style={poem} >
                  <p>Nossa primeira parada <br/>
                  Itapecuru-Mirim <br/>
                  Na escola Luís Gomes <br/>
                  Fizemos grande festim <br/>
                  Cumprindo o nosso papel <br/>
                  Disseminado o cordel<br/>     
                  Numa alegria sem fim</p>
              </div>
              <br/>

            <Carousel cols={4} rows={1} gap={10}>
          <Carousel.Item>
            <img width="100%" src="/images/itapecurumirim2024/1.png" alt = "membro-itapecuru-mirim"/>
            {/* <p style={{textAlign: "center"}}> Desenvolvedor </p> */}
          </Carousel.Item>
          <Carousel.Item>
            <img width="100%" src="/images/itapecurumirim2024/2.png" alt = "membro-itapecuru-mirim"/>
            {/* <p style={{textAlign: "center"}}> Aqui eh a outra</p> */}
          </Carousel.Item>
          <Carousel.Item>
            <img width="100%" src="/images/itapecurumirim2024/3.png" alt = "membro-itapecuru-mirim"/>
          </Carousel.Item>
          <Carousel.Item>
            <img width="100%" src="/images/itapecurumirim2024/4.png" alt = "membro-itapecuru-mirim"/>
          </Carousel.Item>
          
        </Carousel>






            <button onClick={goBack} style= {{display: 'flex', margin: '20px auto', backgroundColor: 'rgb(240, 238, 225)'}}>Voltar</button>
        </div>
    )

    
}

export default ItapecuruMirim2024;






