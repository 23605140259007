import React, {useEffect} from 'react';
import Carousel from 'better-react-carousel'


// Simulate a back button click
function goBack() {
    window.history.back();
}



const Caico2025 = (  ) => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

     const poem = {
      fontFamily: '"Palatino Linotype", "Book Antiqua", Palatino, serif',
      fontStyle: 'italic',
      fontSize: '1.2em',
      lineHeight: '1.6',
      textAlign: 'center',
      marginTop: '10px'
     }

    
    return (
        <div>


            <h1 className="adjustedMargin" style={{textAlign:'center', paddingTop: '5vh'}}>Oficina Sarau e Exposição</h1>
            <p style={{margin: '0 50px'}}>
            A expedição Encanta Cordel vai levar suas oficinas para CAICO</p>
            
              <div style={poem} >
                  <p>... <br/>
                  ... <br/>
                  ... <br/>
                  ... <br/>
                  ... <br/>
                  ...<br/>     
                  ...</p>
              </div>
              <br/>

            <Carousel cols={5} rows={1} gap={10} loop>
          <Carousel.Item>
            <img width="100%" src="/images/caico2024/1.png" alt = "membro-caico"/>
            {/* <p style={{textAlign: "center"}}> Desenvolvedor </p> */}
          </Carousel.Item>
          <Carousel.Item>
            <img width="100%" src="/images/caico2024/2.png" alt = "membro-caico"/>
            {/* <p style={{textAlign: "center"}}> Aqui eh a outra</p> */}
          </Carousel.Item>
          <Carousel.Item>
            <img width="100%" src="/images/caico2024/3.png" alt = "membro-caico"/>
            {/* <p style={{textAlign: "center"}}> Aqui eh a outra</p> */}
          </Carousel.Item>
          <Carousel.Item>
            <img width="100%" src="/images/caico2024/4.png" alt = "membro-caico"/>
            {/* <p style={{textAlign: "center"}}> Aqui eh a outra</p> */}
          </Carousel.Item>
          <Carousel.Item>
            <img width="100%" src="/images/caico2024/5.png" alt = "membro-caico"/>
            {/* <p style={{textAlign: "center"}}> Aqui eh a outra</p> */}
          </Carousel.Item>
          <Carousel.Item>
            <img width="100%" src="/images/caico2024/6.png" alt = "membro-caico"/>
            {/* <p style={{textAlign: "center"}}> Aqui eh a outra</p> */}
          </Carousel.Item>
          <Carousel.Item>
            <img width="100%" src="/images/caico2024/7.png" alt = "membro-caico"/>
            {/* <p style={{textAlign: "center"}}> Aqui eh a outra</p> */}
          </Carousel.Item>
          <Carousel.Item>
            <img width="100%" src="/images/caico2024/8.png" alt = "membro-caico"/>
            {/* <p style={{textAlign: "center"}}> Aqui eh a outra</p> */}
          </Carousel.Item>
          <Carousel.Item>
            <img width="100%" src="/images/caico2024/9.png" alt = "membro-caico"/>
            {/* <p style={{textAlign: "center"}}> Aqui eh a outra</p> */}
          </Carousel.Item>
          <Carousel.Item>
            <img width="100%" src="/images/caico2024/11.png" alt = "membro-caico"/>
            {/* <p style={{textAlign: "center"}}> Aqui eh a outra</p> */}
          </Carousel.Item>
          <Carousel.Item>
            <img width="100%" src="/images/caico2024/12.png" alt = "membro-caico"/>
            {/* <p style={{textAlign: "center"}}> Aqui eh a outra</p> */}
          </Carousel.Item>
          <Carousel.Item>
            <img width="100%" src="/images/caico2024/13.png" alt = "membro-caico"/>
            {/* <p style={{textAlign: "center"}}> Aqui eh a outra</p> */}
          </Carousel.Item>
          <Carousel.Item>
            <img width="100%" src="/images/caico2024/14.png" alt = "membro-caico"/>
            {/* <p style={{textAlign: "center"}}> Aqui eh a outra</p> */}
          </Carousel.Item>
        </Carousel>






            <button onClick={goBack} style= {{display: 'flex', margin: '20px auto', backgroundColor: 'rgb(240, 238, 225)'}}>Voltar</button>
        </div>
    )

    
}

export default Caico2025;






