

import React, {useState, useEffect} from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // Import the FontAwesomeIcon component
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";


import {Link} from 'react-router-dom';
import '../../../App.css';



function Future2025() {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
//     //data for cards

    const cards = [
        {
            title: 'São Luís - MA',
            text: 'A expedição Encanta Cordel vai levar as suas oficinas, sarau e exposição para São Luís em 2025.',
            image: '/images/cards/cordel.jpeg', // this one will become './B-Eventos/evento-1.jpeg' for example... for now the reference is made to the image outisde the images folder (located directly under 'public')
            showButton: false,
            URL: '/próximos-eventos/são-luís',
            //convert the above into URL: '/eventos2025/são-luís' once the event passes
            //also, I want to then change the saoluis2025 page that reflects an event that ALREADY happened.
        },
        {
            title: 'Bacabeira - MA',
            text: 'A expedição Encanta Cordel vai levar as suas oficinas, sarau e exposição para Bacabeira em 2025.',
            image: '/cordel-frente.jpeg',
            URL: '/próximos-eventos/bacabeira',
            //convert the above into URL: '/eventos2025/bacabeira'
        },
        {
            title: 'Caicó - RN',
            text: 'A expedição Encanta Cordel vai levar as suas oficinas, sarau e exposição para Caicó em 2025.',
            image: '/cordel-frente.jpeg',
            URL: '/próximos-eventos/caicó',
                //convert the above into URL: '/eventos2025/são-luís'
        },
        {
            title: 'Patos - PB',
            text: 'A expedição Encanta Cordel vai levar as suas oficinas, sarau e exposição para Patos em 2025.',
            image: '/cordel-frente.jpeg',
            URL: '/próximos-eventos/patos',
        }
    ]

// My task: make the + become a minus when it expands
// Only put the ... if there are more than 100 characters, and not in any case....

    const [isExpanded, setIsExpanded] = useState(false);

    const cardComponent = cards.map((card, i) => {
        const text = isExpanded ? card.text : (card.text.length > 80) ? `${card.text.substring(0, 80)}...`:`${card.text.substring(0, 80)}`;

        const plus = isExpanded ? null : (card.text.length<80) ? null : <span><FontAwesomeIcon icon={faChevronDown} /></span>;
        
        const minus = isExpanded ? (card.text.length < 80) ? null : <span><FontAwesomeIcon icon={faChevronUp} /></span> : null;

        return (
           <Card key={i} className="col-11 col-md-6 col-lg-2 mb-4 m-4">
            <Card.Img variant="top" src={card.image} style={{ marginTop: '10px' }} />
            <Card.Body>
              <Card.Title>{card.title}</Card.Title>
              <Card.Text style={{ marginBottom: '50px' }}>
                {text}            
                <p style={{display: 'inline'}} onClick={() => setIsExpanded(!isExpanded)}>{minus}{plus}</p>     
              </Card.Text>

              <Button
                variant="primary"
                as={Link}
                to={card.URL}
                style={{
                  width: 'auto',
                  backgroundColor: 'rgb(240, 238, 225)',
                  fontWeight: '600',
                  position: 'absolute',
                  bottom: '20px',
                  right: '30px',
                  marginTop: '20px',
                  border: '1px solid black',
                  color: 'black'
                }}
                // onClick={() => setIsExpanded(!isExpanded)}
              >
                {/* {isExpanded ? 'Show less' : 'Show more'} */}
                Saiba mais
              </Button>

            </Card.Body>
          </Card>)
        
        
      });


  return (
    <section className = "py-4 container adjustedMargin">
    <div className= "row justify-content-center">
        <h1 style={{display: 'flex', justifyContent: 'center', alignItems: 'center', fontFamily: 'Xilosa'}}>Eventos - 2025</h1>
        {cardComponent}

    </div>
    </section>
);
}


export default Future2025;