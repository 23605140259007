import React, { useState, useEffect } from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import Card from 'react-bootstrap/Card';



function ContacaoDeHistorias() {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
//     //data for cards

    const cards = [
        {
            title: 'Primeira Historia',
            text: 'Alguem lendo um Cordel...Alguem lendo um Cordel...Alguem lendo um Cordel...Alguem lendo um Cordel...Alguem lendo um Cordel...Alguem lendo um Cordel...Alguem lendo um Cordel...Alguem lendo um Cordel...Alguem lendo um Cordel...Alguem lendo um Cordel...Alguem lendo um Cordel...Alguem lendo um Cordel...Alguem lendo um Cordel...Alguem lendo um Cordel...Alguem lendo um Cordel...Alguem lendo um Cordel...Alguem lendo um Cordel...Alguem lendo um Cordel...Alguem lendo um Cordel...Alguem lendo um Cordel...Alguem lendo um Cordel...Alguem lendo um Cordel...Alguem lendo um Cordel...Alguem lendo um Cordel...Alguem lendo um Cordel...Alguem lendo um Cordel...Alguem lendo um Cordel...Alguem lendo um Cordel...Alguem lendo um Cordel...Alguem lendo um Cordel...Alguem lendo um Cordel...Alguem lendo um Cordel...Alguem lendo um Cordel...Alguem lendo um Cordel...Alguem lendo um Cordel...Alguem lendo um Cordel...Alguem lendo um Cordel...Alguem lendo um Cordel...Alguem lendo um Cordel...Alguem lendo um Cordel...Alguem lendo um Cordel...Alguem lendo um Cordel...Alguem lendo um Cordel...',
            image: './cordel-frente.jpeg',
            URL: '/Próximos-Eventos/evento-1',
            videoId: 'ZqNAzDR2Ul4?si=9TWtSqdft6r4DIVb'
        },
        {
            title: 'Segunda Historia',
            text: ' Some quick example text to build on the card title and make up th bulk of the cards content.',
            image: './cordel-frente.jpeg',
            URL: '/asdasd'
        },
        {
            title: 'Card title3',
            text: ' Some quick example text to build on the card title and make up th bulk of the cards content.',
            image: './cordel-frente.jpeg',
            URL: '/asdasd'
        },
        {
            title: 'Card title3',
            text: ' Some quick example text to build on the card title and make up th bulk of the cards content.',
            image: './cordel-frente.jpeg',
            URL: '/asdasd'
        },
        {
            title: 'Card title3',
            text: ' Some quick example text to build example text to b example text to b example text to b example text to b example text to b example text to b example text to b example text to b example text to b example text to b example text to b example text to b example text to b example text to b example text to b example text to b on the card title and make up th bulk of the cards content.',
            image: './cordel-frente.jpeg',
            URL: '/asdasd'
        },
        {
            title: 'Card title3',
            text: ' Some quick example text to build on the card title and make up th bulk of the cards content.',
            image: './cordel-frente.jpeg',
            URL: '/asdasd'
        },
    ]
    


    const [isExpanded, setIsExpanded] = useState(false);

    const cardComponent = cards.map((card,  i) => {
        const text = isExpanded ? card.text : (card.text.length > 120) ? `${card.text.substring(0, 120)}...`:`${card.text.substring(0, 120)}`;

        const plus = isExpanded ? null : (card.text.length<120) ? null : <span>&nbsp;<i class="expand-icon fa-solid fa-chevron-down"></i></span>;
        
        const minus = isExpanded ? (card.text.length < 120) ? null : <span>&nbsp;<i class="expand-icon fa-solid fa-chevron-up"></i></span> : null;


        const embedUrl = `https://www.youtube.com/embed/${card.videoId}`;

        return( 
            <Card key={i} className = "col-11 col-md-6 col-lg-3 mb-4 m-3">
            <div className="embed-responsive embed-responsive-16by9">
            <iframe className="embed-responsive-item" src={embedUrl} title={card.title} style={{width:"100%", paddingTop: "10px"}} allowFullScreen />
            </div>
            <Card.Body>
            <Card.Title>{card.title}</Card.Title>
            <Card.Text style={{ marginBottom: '50px' }}>
                {text}            
                <p style={{display: 'inline'}} onClick={() => setIsExpanded(!isExpanded)}>{minus}{plus}</p>     
            </Card.Text>
            </Card.Body>
            </Card>
        );
    });

  return (
    <section className = "py-4 container adjustedMargin" >
    <div className= "row justify-content-center">
        <h1 className = "row justify-content-center" style={{fontFamily: 'Xilosa'}}>Contação de Histórias</h1>
        {cardComponent}
    </div>
    </section>
);
}

export default ContacaoDeHistorias;










