import React from 'react';

function goBack() {
    window.history.back();
}

const English = () => {
  return (
    <div style={styles.container}>
      <h1>Marko está trabalhando</h1>
      <p>Encanta Cordel estará disponível em inglês em 2025.</p>
      <button onClick={goBack} style= {{display: 'flex', margin: '20px auto', backgroundColor: 'rgb(240, 238, 225)'}}>Voltar</button>

    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    fontFamily: 'Arial, sans-serif',
    textAlign: 'center',
  },
};

export default English;