

const data = {
    cardData: [
        {
            id:1,
            img: '/images/cordelteca/fcja.png',
            title: 'Fundação Casa de José Américo',
            desc: 'João Pessoa - a doação de acervo foi realizada aqui em junho de 2024...',
            linkto: 'https://fcja.pb.gov.br/biblioteca'
        },
        {
            id:2,
            img: '/images/E-Cordelteca/os-sofrimentos-de-alzira.webp',
            title: 'FUNAD',
            desc: 'João Pessoa - a doação de acervo foi realizada aqui em junho de 2024...',
            linkto: 'https://funad.pb.gov.br/'
        },
        {
            id:3,
            img: '/images/E-Cordelteca/o-valor-da-mulher.webp',
            title: 'Biblioteca Juarez da Gama Batista',
            desc: 'João Pessoa - a doação de acervo foi realizada aqui em junho de 2024...',
            linkto: 'https://funesc.pb.gov.br/conheca-a-funesc/biblioteca'
        },
        {
            id:4,
            img: '/images/E-Cordelteca/a-seca-do-ceara.webp',
            title: 'Casa de Cultura Popular de Caicó Sobrado Padre Brito Guerra',
            desc: 'Caicó - a doação de acervo foi realizada aqui em junho de 2024...',
            linkto: 'https://www.instagram.com/casasdeculturapopulardorn/'
        },
        {
            id:5,
            img: '/images/E-Cordelteca/a-seca-do-ceara.webp',
            title: 'Biblioteca Municipal Benedito Buzar',
            desc: 'Itapecuru mirim - a doação de acervo foi realizada aqui em junho de 2024...',
            linkto: 'https://www.itapecurumirim.ma.gov.br/unidadesaude.php?id=88'
        },
        {
            id:6,
            img: '/images/E-Cordelteca/os-sofrimentos-de-alzira.webp',
            title: 'Biblioteca Pública Benedito Leite',
            desc: '...São Luís - a doação de acervo foi realizada aqui em junho de 2024...',
            linkto: 'http://casas.cultura.ma.gov.br/bpbl/index.php?page=biblioteca'
        },
        // {
        //     id:1,
        //     img: '',
        //     title: 'A Seca do Ceara',
        //     desc: 'Este livro eh sobre...'
        // },
        // {
        //     id:2,
        //     img: '',
        //     title: 'Os Sofrimentos de Alzira',
        //     desc: 'desc 2'
        // },
        // {
        //     id:3,
        //     img: '',
        //     title: 'O Valor da Mulher',
        //     desc: 'desc 3'
        // }

    ]
}

export default data;