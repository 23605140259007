import React, {useEffect} from "react";

// import '../../Equipe.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Card from 'react-bootstrap/Card';




const EquipeTecnica = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    const datas = [
        {
            image: "img2.jpg",
            name: "Karin Picado",
            title: "Produtora Executiva",
            description: "Some text that describes me lorem ipsum ipsum lorem.",
            showEmail: false,
            showInstagram: false,
            showNumber: false,
            email: "encantacordel@gmail.com",
            instagram: 'asd',
            number: ' dfdf'
        },
        {
            image: "./images/equipe/markokocev.png",
            name: "Marko Kocev",
            title: "Desenvolvedor de sites",            
            description: "Marko é um estrangeiro de coração brasileiro. Ele é apaixonado pela cultura brasileira e aproveitou a oportunidade para criar este site que promove a cultura popular nordestina.",
            showEmail: true,
            showInstagram: true,
            showNumber: true,
            email: "markocev10 @gmail.com",
            instagram: "inglesaustraliano",
            number: " (83) 99911-9364"
        },
        {
            image: "img3.jpg",
            name: "Edcarlos Medeiros",
            title: "Designer",
            description: "Some text that describes me lorem ipsum ipsum lorem.",
            showEmail: false,
            showInstagram: false,
            showNumber: false,
            email: "example@example.com",	
            instagram: ''
        },
        {
            image: "./images/equipe/samuelbarreto.png",
            name: "Samuel Barreto ",
            title: "Áudio e vídeo",
            description: "Publicitário de formação; pós graduado em produção cultural; artista visual; bonequeiro e educador social.",
            showEmail: true,
            showEmail2: true,
            showInstagram: false,
            showNumber: true,
            email: "inventivocultural @gmail.com",
            email2: "samuelbarreto.nas @gmail.com",
            number: ' (83) 98231-0441'            
        },
        {
            image: "./images/equipe/nanavianna.png",
            name: "Nana Vianna",
            title: "Intérprete de libras",
            description: "Some text that describes me lorem ipsum ipsum lorem.",
            showEmail: false,
            showInstagram: false,
            showNumber: false,
            email: "example@example.com",
            instagram: ''
        },
        {
            image: "img3.jpg",
            name: "Bruno Oliveira",
            title: "Áudio e vídeo",
            description: "Some text that describes me lorem ipsum ipsum lorem.",
            showEmail: false,
            showInstagram: false,
            showNumber: false,
            email: "example@example.com",
            instagram: 'asdasd'
        },
        {
            image: "img3.jpg",
            name: "Williana Almeida",
            title: "Áudio e vídeo",
            description: "Publicitária de formação, Graduanda em Turismo, Redatora, Copywriter, Social Media, Publicitária e Produtora de eventos com atuação para pessoas, marcas e empresas.",
            showEmail: true,
            showInstagram: true,
            showNumber: true,
            email: "willianaalmeida10 @gmail.com",
            number: ' (83) 98713-1131'
        }
    ]

//JUST PUT {datas.name ,, .text etc.... integrate the below 3 parts into 1, with reference to the objects above}

    const dataComponent = datas.map((data, i) => {

    return (
        <Card key={i} className = "col-11 col-md-6 col-lg-3 mb-4 m-3">
        <Card.Img variant="top" src={data.image} alt="imagem de identificacao" style ={{marginTop: '10px'}} />
         <Card.Body>
         <Card.Title>{data.name}</Card.Title>
         <Card.Subtitle>{data.title}</Card.Subtitle>
         <Card.Text>
             <br/>
             {data.description}
             <br/><br/>
             {data.showEmail && <p><i class="fa-regular fa-envelope"></i> {data.email}</p>}
             {data.showEmail2 && <p><i class="fa-regular fa-envelope"></i> {data.email2}</p>}
             {data.showInstagram && <p ><i class="fa-brands fa-instagram"></i> <a href={"https://www.instagram.com/" + data.instagram} target="_blank" rel="noreferrer">{data.instagram}</a></p> }
             {data.showNumber && <p><i class="fas fa-phone"></i>{data.number}</p>}
             
         </Card.Text>
         {/* <Button variant="primary" as={Link} to = {data.URL} style={{width: "50%", margin: "0 auto", display: "flex", justifyContent: "center", backgroundColor: "rgb(186,86,80)", fontWeight: "600"}}>Leia mais</Button> */}
         </Card.Body>   
        </Card>
       
    )
    })

    return(
        <section className = "py-4 container adjustedMargin">
        <div className= "row justify-content-center">
        <h1 style={{display: 'flex', justifyContent: 'center', alignItems: 'center', fontFamily: 'Xilosa'}}>Equipe Técnica</h1>

            {dataComponent}
        </div>
        </section>
    )
}


export default EquipeTecnica;