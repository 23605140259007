import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';

import 'bootstrap/dist/css/bootstrap.min.css'; // CSS styling, below are JS components
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

import {Link} from 'react-router-dom';
import '../../../App.css'




const AppHeader = () => {
  const [expanded, setExpanded] = useState(false);
  const [scrolled, setScrolled] = useState(false);



  useEffect(() => {
    const handleScroll = () => {
      // Set scrolled to true when the user has scrolled beyond a certain threshold (e.g., 100 pixels)
      setScrolled(window.scrollY > 10);
    };

    // Attach the event listener when the component mounts
    window.addEventListener('scroll', handleScroll);


    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };

  }, []);


    return (
    <div style={{marginBottom:'50px'}}>
        <Navbar expanded= {expanded} expand="lg" className = {scrolled ? 'scrolling2' : 'scrolling1' } style={{position: 'fixed', width: '100%', top: '0', zIndex: '1000'}}>
      <Container>
        <Navbar.Brand as={Link} to = "/" className="d-none d-sm-block">Expedição Encanta Cordel</Navbar.Brand>
        <Navbar.Brand as={Link} to = "/" className="d-sm-none">Expedição Encanta Cordel</Navbar.Brand>
        <Navbar.Toggle 
        aria-controls="basic-navbar-nav" 
        onClick={() => setExpanded(expanded ? false : "expanded")}
        />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="move-to-right navbar-fixed-top" style={{fontSize: "14px"}}>
            <Nav.Link as={Link} to = "/" onClick={() => setExpanded(false)} >Início</Nav.Link>
            <Nav.Link as={Link} to = "/quem-somos" onClick={() => setExpanded(false)}>Quem somos</Nav.Link>
            <NavDropdown title="Eventos" id="basic-nav-dropdown">
                <NavDropdown.Item as={Link} to = "/eventos" onClick={() => setExpanded(false)}>Eventos</NavDropdown.Item>
                <NavDropdown.Item as={Link} to = "/próximos-eventos" onClick={() => setExpanded(false)}>Próximos Eventos</NavDropdown.Item>
            </NavDropdown>

            <NavDropdown title="Vídeos" id="basic-nav-dropdown">
                <NavDropdown.Item as={Link} to = "/oficina-viva" onClick={() => setExpanded(false)}>Oficina Viva</NavDropdown.Item>
                <NavDropdown.Item as={Link} to = "/contação-de-histórias" onClick={() => setExpanded(false)}>Contação de Histórias</NavDropdown.Item>
                <NavDropdown.Item as={Link} to = "/memórias-afetivas" onClick={() => setExpanded(false)}>Memórias Afetivas</NavDropdown.Item>
            </NavDropdown>

            <NavDropdown title="Colaboradores" id="basic-nav-dropdown">
                <NavDropdown.Item as={Link} to = "/território-de-identidades" onClick={() => setExpanded(false)}>Território de Identidades</NavDropdown.Item>
                <NavDropdown.Item as={Link} to = "/equipe-técnica" onClick={() => setExpanded(false)}> Equipe Técnica </NavDropdown.Item>            
            </NavDropdown>

            <Nav.Link as={Link} to = "/cordelteca" onClick={() => setExpanded(false)}>Cordelteca</Nav.Link>
            <NavDropdown title="Idioma" id="basic-nav-dropdown">
                <NavDropdown.Item as={Link} to = "/en" onClick={() => {setExpanded(false)}} >
                  Inglês &nbsp; 
                  <img
                  src="https://flagcdn.com/24x18/gb.png"
                  srcset="https://flagcdn.com/48x36/gb.png 2x,
                    https://flagcdn.com/72x54/gb.png 3x"
                  width="24"
                  height="18"
                  alt="United Kingdom"></img>
                  </NavDropdown.Item>
              </NavDropdown>
            <Nav.Link as={Link} to = "/contato" onClick={() => setExpanded(false)}>Contato</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
        </Navbar>
        </div>
        );
}

export default AppHeader;


