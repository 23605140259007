import React, {useEffect} from 'react';
import Carousel from 'better-react-carousel'



const Exposicao = (  ) => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      const downloadPdf = () => {
      const pdfUrl = '/cordestinadas_folheto.pdf';

      // Create an invisible anchor element
      const link = document.createElement('a');
      link.style.display = 'none';
      document.body.appendChild(link);
  
      // Set the href attribute of the anchor element to the PDF URL
      link.href = pdfUrl;
  
      // Set the download attribute to force download the PDF instead of opening it in the browser
      link.setAttribute('download', 'cordestinadas_folheto.pdf');
  
      // Trigger a click event on the anchor element to start the download
      link.click();
  
      // Clean up: remove the anchor element from the DOM
      document.body.removeChild(link);
      }

    
    return (
        <div className="adjustedMargin">
            <h1 style={{textAlign:'center', paddingTop: '5vh',  fontFamily: 'Xilosa'}}>Cordestinadas</h1>
            <h3 style={{display: 'flex', justifyContent:'center', alignItems: 'center', margin: '20px 20px'}}>Literatura de Cordel: representação e participação feminina</h3>
        <div>
      <button onClick={downloadPdf} style={{ display: 'flex', justifyContent:'center', alignItems: 'center', backgroundColor: 'rgb(240, 238, 225)', margin: '10px auto'}}>Baixar PDF</button>
    </div>

            <Carousel cols={3} rows={2} gap={10} >
          <Carousel.Item>
            <img width="100%" src="/images/cordestinadas/1.png" alt="exposicao-membro"/>
            {/* <p style={{textAlign: "center"}}> Desenvolvedor</p> */}
          </Carousel.Item>
          <Carousel.Item>
            <img width="100%" src="/images/cordestinadas/2.png" alt="exposicao-membro"/>
            {/* <p style={{textAlign: "center"}}> Aqui eh a outra</p> */}
          </Carousel.Item>
          <Carousel.Item>
            <img width="100%" src="/images/cordestinadas/3.png" alt="exposicao-membro"/>
          </Carousel.Item>
          <Carousel.Item>
            <img width="100%" src="/images/cordestinadas/4.png" alt="exposicao-membro"/>
          </Carousel.Item>
          <Carousel.Item>
            <img width="100%" src="/images/cordestinadas/5.png" alt="exposicao-membro"/>
          </Carousel.Item>
          <Carousel.Item>
            <img width="100%" src="/images/cordestinadas/6.png" alt="exposicao-membro"/>
          </Carousel.Item>
        </Carousel>
        </div>
    )
}


export default Exposicao;






