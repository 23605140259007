import React, {useEffect} from 'react';
import Accordion from 'react-bootstrap/Accordion';

const About = () => {
    
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return(
        <div className="col-11 col-md-9 adjustedMargin" style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: "110px", paddingBottom: "50px", fontSize: "16px"}}>
         <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>O que é?</Accordion.Header>
        <Accordion.Body>
        A Expedição Encanta Cordel é um projeto inovador destinado a fortalecer a preservação e
valorização do Cordel, uma importante manifestação cultural brasileira. <strong>Este projeto é enquadrado
no artigo 18 da Lei no 8.313/91 de incentivo à cultura e patrocinado pelo Instituto Cultural Vale.</strong>
Consiste em uma Mostra Cultural que percorrerá quatro municípios do país (Caicó - RN, João
Pessoa - PB, Itapecuru Mirim e São Luís - MA), oferecendo uma programação gratuita e aberta ao
público em geral. Essa programação será composta por uma variedade de ações educativo-culturais,
incluindo oficinas de cordel e xilogravura, exposições e doação de folhetos de Cordel para bibliotecas
públicas ou comunitárias nos locais de realização.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Por que estamos organizando?</Accordion.Header>
        <Accordion.Body>
        A organização de um evento como a Expedição Encanta Cordel é importante por diversos
motivos:
Expedição
Encanta Cordel
<br/><br/>
1. Fortalecimento e preservação da cultura do Cordel: O Cordel é uma forma tradicional de
expressão literária popular no Brasil, especialmente na região Nordeste. Ao promover um
evento que valoriza e difunde essa manifestação cultural, contribui-se para a sua
preservação e continuidade ao longo das gerações; <br/><br/>
2. Salvaguarda do patrimônio cultural imaterial: O Cordel é considerado um patrimônio
cultural imaterial brasileiro; <br/><br/>
3. Ao realizar um evento como a Expedição Encanta Cordel, as ações educativo-culturais e
as exposições ajudam a proteger e promover esse patrimônio, evitando sua diluição ou
esquecimento; <br/><br/>
4. Acesso à cultura para todos: Ao oferecer uma programação gratuita, com ações
educativo-culturais e exposições em diferentes municípios, a Expedição Encanta Cordel
possibilita o acesso à cultura para um público mais amplo. Isso é fundamental para
garantir que pessoas de diferentes faixas etárias e classes sociais possam vivenciar e
apreciar essa forma de arte popular e tradicional; <br/><br/>
5. Inclusão e acessibilidade: Ao contar com intérpretes de Libras e material impresso em
braile, a Expedição Encanta Cordel se torna inclusiva e acessível para pessoas com
deficiência auditiva ou visual. Isso é um passo importante para garantir que todos os cidadãos tenham a oportunidade de participar do evento e desfrutar das atividades
oferecidas; <br/><br/>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>Qual o objetivo?</Accordion.Header>
        <Accordion.Body>
        Promover atividades de formação e cultura em quatro municípios brasileiros, com o objetivo de
enaltecer, disseminar e preservar os elementos do Cordel como parte fundamental do patrimônio
cultural nacional.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>Quais as atividades do evento?</Accordion.Header>
        <Accordion.Body>
        <strong>OFICINAS:</strong> de criação de folheto de Cordel; de declamação de Cordel; de ilustração (capa de Cordel). <br/><br/>
        <strong>EXPOSIÇÃO:</strong> Exposição de artes visuais sobre o Cordel;<br/><br/>
        <strong>FESTIVAL/MOSTRA:</strong> Palco Aberto para declamação de Cordel; Círculo de conversa sobre o Cordel.<br/><br/>
        <strong>DOAÇÃO DE ACERVO:</strong> Doação de mil Cordéis para bibliotecas pública ou comunitária.<br/><br/>
        <strong>MAPEAMENTO DO CORDEL:</strong> mapeamento abrangente da cadeia produtiva do cordel, abordando
aspectos relacionados a cordelistas, declamadores, capistas, folheteiros, gráficas, entre outros.
Essas informações serão fornecidas ao IPHAN com o objetivo de subsidiar a formulação de políticas
públicas que atendam às demandas e necessidades dos participantes envolvidos nesse importante
setor cultural.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
        <Accordion.Header>Como ocorrerão as atividades do evento?</Accordion.Header>
        <Accordion.Body>
        OFICINAS: as ações educativas alcançarão (professores e alunos da rede pública) sendo oferecida 120
vagas nas ações formativas. Sendo oficinas: de ilustração no estilo de Xilo; de criação de folheto de Cordel;
de declamação de Cordel.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="5">
        <Accordion.Header>Quem são nossos parceiros?</Accordion.Header>
        <Accordion.Body>
        Patrocínio da Vale; Realização da Milagres Produções Artísticas; Apoio: A Secretaria de Estado da Cultura da
Paraíba - SECULT-PB; Fundação Centro Integrado de Apoio à Pessoa com Deficiência -FUNAD.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="6">
        <Accordion.Header>Como você pode participar?</Accordion.Header>
        <Accordion.Body>
        ALUNOS E PROFESSORES DE ESCOLAS PÚBLICAS - faça sua inscrição pelo formulário: <br/><br/>
CORDELISTAS E DEMAIS PROFISSIONAIS DO SEGMENTO DO CORDEL - faça parte do mapeamento
respondendo ao formulário: <br/><br/>
PÚBLICO EM GERAL: acompanhe a agenda de programação na sua cidade, doe cordéis (que serão
destinados à biblioteca pública); <br/><br/>
PESSOAS JURÍDICAS: apoie nossas ações, entrando em contato conosco:
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="7">
        <Accordion.Header>Quais os benefícios de participar?</Accordion.Header>
        <Accordion.Body>
        Um evento como a Expedição Encanta Cordel pode trazer diversos benefícios significativos para a
preservação e valorização do Cordel, bem como para a cultura brasileira e as comunidades envolvidas. <br/><br/>
Preservação cultural: Ao promover oficinas de cordel e ilustração estilo xilo, a Expedição Encanta Cordel
ajuda a preservar e manter viva essa tradição, transmitindo conhecimentos e técnicas às novas gerações. <br/><br/>
Valorização da cultura regional: O evento percorrerá municípios em diferentes estados do Nordeste,
contribuindo para valorizar a cultura regional específica dessas áreas, dando destaque às suas
particularidades e diversidades culturais. <br/><br/>
Acesso à cultura: Ao oferecer uma programação gratuita e aberta ao público em geral, a Expedição Encanta
Cordel torna o Cordel acessível a um amplo espectro de pessoas, incluindo aquelas que não teriam
oportunidade de entrar em contato com essas manifestações culturais de outra forma. <br/><br/>
Estímulo à criatividade e expressão artística: As oficinas de cordel e xilogravura proporcionam um espaço para
que os participantes possam expressar sua criatividade e aprender novas habilidades artísticas, estimulando a
produção de novos trabalhos e ampliando o alcance da arte do Cordel. <br/><br/>
Fomento à leitura e literatura: A doação de folhetos de Cordel para bibliotecas públicas ou comunitárias
contribui para enriquecer o acervo dessas instituições, incentivando a leitura e disseminação da literatura de
Cordel.<br/><br/>
Intercâmbio cultural: Ao percorrer diferentes municípios, a Expedição Encanta Cordel cria oportunidades para
o intercâmbio cultural entre os participantes, promovendo a troca de experiências e conhecimentos entre as
comunidades envolvidas. <br/><br/>
Impacto econômico: Eventos culturais como esse podem impulsionar o turismo local, atraindo visitantes
interessados na cultura do Cordel e na programação oferecida. Isso pode beneficiar a economia das regiões
envolvidas. <br/><br/>
Promoção de artistas locais: A Mostra Cultural pode incluir exposições que destacam artistas locais e suas
obras, oferecendo-lhes visibilidade e oportunidades para divulgação de seus trabalhos. <br/><br/>
Sensibilização para a importância da preservação cultural: Ao promover o Cordel e sua história, a Expedição
Encanta Cordel aumenta a conscientização sobre a importância da preservação de manifestações culturais
tradicionais e patrimônios imateriais do país. <br/><br/>
Fortalecimento da identidade cultural: O evento contribui para fortalecer a identidade cultural das
comunidades visitadas, resgatando e valorizando suas raízes e tradições. <br/><br/>
Em resumo, a Expedição Encanta Cordel tem o potencial de trazer benefícios tanto para a preservação do
Cordel e da cultura brasileira, quanto para o enriquecimento da vida cultural e social das comunidades

participantes. Além disso, o evento pode criar um legado duradouro ao estimular a continuidade do interesse e
engajamento com o Cordel após sua realização. 
        </Accordion.Body>
      </Accordion.Item>

    </Accordion>
        </div>
       
    )
}


export default About;