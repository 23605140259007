import React, {useEffect} from "react";

// import '../../Equipe.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Card from 'react-bootstrap/Card';




const TerritorioDeIdentidades = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    const datas = [
        {
            image: "/images/territorio/participant4.png",
            name: "Dodora Medeiros",
            title: "Membro1",
            description: "Some text that describes me lorem ipsum ipsum lorem.",
            showEmail: false,
            showInstagram: false,
            showNumber: false,
            email: "...@gmail.com",
            instagram: 'asd',
            number: ' dfdf'
        },
        {
            image: "/images/territorio/participant5.png",
            name: "Djalma Alves",
            title: "Membro2",            
            description: "Djalma .....",
            showEmail: true,
            showInstagram: true,
            showNumber: true,
            email: "@gmail.com",
            instagram: "ing",
            number: " (83) "
        },
        {
            image: "/images/territorio/participant6.png",
            name: "Ana Clemente",
            title: "Membro3",
            description: "Some text that describes me lorem ipsum ipsum lorem.",
            showEmail: false,
            showInstagram: false,
            showNumber: false,
            email: "example@example.com",	
            instagram: ''
        },
        {
            image: "/images/territorio/participant1.png",
            name: "Maria das Graças Dantas",
            title: "Membro4",
            description: "Publicitário de formação; pós graduado em produção cultural; artista visual; bonequeiro e educador social.",
            showEmail: true,
            showEmail2: true,
            showInstagram: false,
            showNumber: true,
            email: "@gmail.com",
            email2: " @gmail.com",
            number: ' (83) '            
        },
        {
            image: "/images/territorio/participant2.png",
            name: "Jussara Santos",
            title: "Membro5",
            description: "Some text that describes me lorem ipsum ipsum lorem.",
            showEmail: false,
            showInstagram: false,
            showNumber: false,
            email: "example@example.com",
            instagram: ''
        },
        {
            image: "/images/territorio/participant3.png",
            name: "Fátima Santos",
            title: "Membro6",
            description: "Some text that describes me lorem ipsum ipsum lorem.",
            showEmail: false,
            showInstagram: false,
            showNumber: false,
            email: "example@example.com",
            instagram: 'asdasd'
        },
        {
            image: "/images/territorio/participant7.png",
            name: "Matheus Nascimento",
            title: "Membro7",
            description: "...",
            showEmail: true,
            showInstagram: true,
            showNumber: true,
            email: "@gmail.com",
            number: ' (83) '
        }
    ]

//JUST PUT {datas.name ,, .text etc.... integrate the below 3 parts into 1, with reference to the objects above}

    const dataComponent = datas.map((data, i) => {

    return (
        <Card key={i} className = "col-11 col-md-6 col-lg-3 mb-4 m-3">
        <Card.Img variant="top" src={data.image} alt="imagem de identificacao" style ={{marginTop: '10px'}} />
         <Card.Body>
         <Card.Title>{data.name}</Card.Title>
         <Card.Subtitle>{data.title}</Card.Subtitle>
         <Card.Text>
             <br/>
             {data.description}
             <br/><br/>
             {data.showEmail && <p><i class="fa-regular fa-envelope"></i> {data.email}</p>}
             {data.showEmail2 && <p><i class="fa-regular fa-envelope"></i> {data.email2}</p>}
             {data.showInstagram && <p ><i class="fa-brands fa-instagram"></i> <a href={"https://www.instagram.com/" + data.instagram} target="_blank" rel="noreferrer">{data.instagram}</a></p> }
             {data.showNumber && <p><i class="fas fa-phone"></i>{data.number}</p>}
             
         </Card.Text>
         {/* <Button variant="primary" as={Link} to = {data.URL} style={{width: "50%", margin: "0 auto", display: "flex", justifyContent: "center", backgroundColor: "rgb(186,86,80)", fontWeight: "600"}}>Leia mais</Button> */}
         </Card.Body>   
        </Card>
       
    )
    })

    return(
        <section className = "py-4 container adjustedMargin">
        <div className= "row justify-content-center">
        <h1 style={{display: 'flex', justifyContent: 'center', alignItems: 'center', fontFamily: 'Xilosa'}}>Território de Identidades</h1>

            {dataComponent}
        </div>
        </section>
    )
}


export default TerritorioDeIdentidades;