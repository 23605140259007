import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Common from '../src/components/PT/1-Common/Common.js';
import FrontCover from '../src/components/PT/2-FrontCover/FrontCover.js'
import About from '../src/components/PT/3-About/About.js'
import Events from '../src/components/PT/4-Events/Events.js'
import Exposicao from '../src/components/PT/4-Events/Exposicao.js';
import Events2024 from '../src/components/PT/4-Events/Events2024.js';

import SaoLuis2024 from '../src/components/PT/4-Events/2024/SaoLuis.js';
import ItapecuruMirim2024 from '../src/components/PT/4-Events/2024/ItapecuruMirim.js';
import Caico2024 from '../src/components/PT/4-Events/2024/Caico.js';
import JoaoPessoa2024 from '../src/components/PT/4-Events/2024/JoaoPessoa.js';

import Future2025 from '../src/components/PT/5-Future/Future2025.js';
//only start using the import below once one of the 2025 events occur.
// import Passed2025 from '../src/components/PT/5-Future/Passed2025.js';

//the following 2025 cities will be unique, as they will change their description and location as they occur (from eventos/proximos-eventos TO eventos/eventos/2025.)
import SaoLuis2025 from './components/PT/4-Events/2025/SaoLuis.js';
import Bacabeira2025 from './components/PT/4-Events/2025/Bacabeira.js';
import Caico2025 from './components/PT/4-Events/2025/Caico.js';
import Patos2025 from './components/PT/4-Events/2025/Patos.js';

import OficinaViva from './components/PT/6-Videos/OficinaViva/OficinaViva.js'
import OficinaBabau from './components/PT/6-Videos/OficinaViva/OficinaBabau.js'
import OficinaCordel from './components/PT/6-Videos/OficinaViva/OficinaCordel.js'
import OficinaRepente from './components/PT/6-Videos/OficinaViva/OficinaRepente.js'
import OficinaArtesVisuais from './components/PT/6-Videos/OficinaViva/OficinaArtesVisuais.js'
import ContacaoDeHistorias from './components/PT/6-Videos/ContacaoDeHistorias/ContacaoDeHistorias.js'
import MemoriasAfetivas from './components/PT/6-Videos/MemoriasAfetivas/MemoriasAfetivas.js';

import TerritorioDeIdentidades from './components/PT/7-Team/TerritorioDeIdentidades.js';
import EquipeTecnica from './components/PT/7-Team/EquipeTecnica.js';

import Cordelteca from './components/PT/8-Cordelteca/Cordelteca.js';

import English from './components/PT/9-EN/English.js';

import Contact from './components/PT/10-Contact/Contact.js';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
        <Route path = "/" element={<><Common /><FrontCover /></>} />
        <Route path = "/quem-somos" element={<><Common /><About /></>} />
        <Route path = "/eventos" element={<><Common /><Events /></>} />
        <Route path = "/eventos/exposicao" element={<><Common /><Exposicao /></>} />
        <Route path = "/eventos/eventos2024" element={<><Common /><Events2024 /></>} />
        <Route path = "/eventos/eventos2024/são-luís" element={<><Common /><SaoLuis2024 /></>} />
        <Route path = "/eventos/eventos2024/itapecuru-mirim" element={<><Common /><ItapecuruMirim2024 /></>} />
        <Route path = "/eventos/eventos2024/caicó" element={<><Common /><Caico2024 /></>} />
        <Route path = "/eventos/eventos2024/joão-pessoa" element={<><Common /><JoaoPessoa2024 /></>} />
        <Route path = "/próximos-eventos" element={<><Common /><Future2025 /></>} />
        {/* only after this first 2025 event, I want to comment the above and uncomment the below. Going into Passed2025 and only including the event/s that occured. Proximos-Eventos will only be accessible via Eventos>Proximos-Eventos and NOT Eventos > Eventos > 2025. */}
        {/* <Route path = "/eventos/eventos2025" element={<><Common /><Passed2025 /></>} /> */}
        <Route path = "/próximos-eventos/são-luís" element={<><Common /><SaoLuis2025 /></>} />
        {/* only after the above event has happened, then i want to change it into the following: */}
        {/* <Route path = "/eventos/eventos2025/são-luís" element={<><Common /><SaoLuis2025 /></>} /> */}

        <Route path = "/próximos-eventos/bacabeira" element={<><Common /><Bacabeira2025 /></>} />
        <Route path = "/próximos-eventos/caicó" element={<><Common /><Caico2025 /></>} />
        <Route path = "/próximos-eventos/patos" element={<><Common /><Patos2025 /></>} />

        <Route path = "/oficina-viva" element={<><Common /><OficinaViva /></>} />
        <Route path = "/oficina-viva/babau" element={<><Common /><OficinaBabau /></>} />
        <Route path = "/oficina-viva/cordel" element={<><Common /><OficinaCordel /></>} />
        <Route path = "/oficina-viva/repente" element={<><Common /><OficinaRepente /></>} />
        <Route path = "/oficina-viva/artes-visuais" element={<><Common /><OficinaArtesVisuais /></>} />
        <Route path = "/contação-de-histórias" element={<><Common /><ContacaoDeHistorias /></>} />
        <Route path = "/memórias-afetivas" element={<><Common /><MemoriasAfetivas /></>} />
        
        <Route path = "/território-de-identidades" element={<><Common /><TerritorioDeIdentidades /></>} />
        <Route path = "/equipe-técnica" element={<><Common /><EquipeTecnica /></>} />

        <Route path = "/cordelteca" element={<><Common /><Cordelteca /></>} />

        <Route path = "/en" element={<><English /></>} />

        <Route path = "/contato" element={<><Common /><Contact /></>} />

        </Routes>
      </Router>
    </div>
  );
}

export default App;
