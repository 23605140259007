import React, {useState, useEffect} from 'react';
import data from './data';
import {Link} from 'react-router-dom';


const Cordelteca = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    const [filter, setFilter] = useState('')
    const searchText = (event) => {
        setFilter(event.target.value);
    }
    // console.warn(filter)
    let dataSearch = data.cardData.filter(item => {
        return Object.keys(item).some(key=>
            item[key].toString().toLowerCase().includes(filter.toString().toLowerCase()))
    })

    return(
        <section className = "py-4 container adjustedMargin">
            <div className= "row justify-content-center">

                <div className="col-12 mb-5">
                    <div className="mb-1 mt-2 mx-auto text-center">
                        <label className= "form-label h4">Procure uma cordelteca abaixo:</label>
                        <div className="col-sm-6 mx-auto">
                        <input 
                            type="text"
                            className="form-control"
                            value={filter}
                            onChange={searchText.bind(this)}
                        />
                        </div>  
                    </div>
                </div>
{/* BIG NOTE: THE DATA WHERE THE BELOW MAP FUNCTION DRAWS DATA FROM IS FOUND IN DATA.JS (in src, not even in components). item.linkto comes from the linkto property that is defined in my data.js file! */}
                {dataSearch.map((item, index)=> {
                    return(
                        <div className = "col-11 col-md-6 col-lg-3 mx-0 mb-4 ">
                        <Link to={`${item.linkto}`} target="_blank" className="card-link">
                        <div className="card p-0 overflow-hidden h-100 shadow" alt={item.title}>
                            <img src={item.img} className="card-img-top" alt="cordelteca"/>
                            <div className="card-body">
                                <h5 className="card-title"> {item.title} </h5>
                                <p className="card-text">{item.desc}</p>
                            </div>
                        </div>
                        </Link>
                        </div>
                    )
                })}

            </div>
        </section>
    )
}

export default Cordelteca