

import React, {useState, useEffect} from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';




import {Link} from 'react-router-dom';
import '../../../App.css';



function Events() {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
//     //data for cards

    const cards = [
        {
            title: '2024',
            text: 'Em 2024, houve eventos que aconteceram em São Luís, Itapecuru Mirim, Caicó e João Pessoa',
            image: '/images/cards/cordel.jpeg', // this one will become './B-Eventos/evento-1.jpeg' for example... for now the reference is made to the image outisde the images folder (located directly under 'public')
            URL: '/eventos/eventos2024/'
        },
        {
            title: '2025',
            text: 'Em 2025, planejamos ter eventos em São Luís, Bacabeira, Caicó e Patos',
            image: '/',
            URL: '/próximos-eventos'
        }
    ]


// My task: make the + become a minus when it expands
// Only put the ... if there are more than 100 characters, and not in any case....

    const [isExpanded, setIsExpanded] = useState(false);

    const cardComponent = cards.map((card, i) => {
        const text = isExpanded ? card.text : (card.text.length > 120) ? `${card.text.substring(0, 120)}...`:`${card.text.substring(0, 120)}`;

        const plus = isExpanded ? null : (card.text.length<120) ? null : <span>&nbsp;<i class="expand-icon fa-solid fa-chevron-down"></i></span>;
        
        const minus = isExpanded ? (card.text.length < 120) ? null : <span>&nbsp;<i class="expand-icon fa-solid fa-chevron-up"></i></span> : null;

        return (
          
          <Card key={i} className="col-11 col-md-6 col-lg-3 mb-4 m-4">
            <Card.Img variant="top" src={card.image} style={{ marginTop: '10px'}} />
            <Card.Body>
              <Card.Title>{card.title}</Card.Title>
              <Card.Text style={{ marginBottom: '50px' }}>
                {text}            
                <p style={{display: 'inline'}} onClick={() => setIsExpanded(!isExpanded)}>{minus}{plus}</p>     
              </Card.Text>

              <Button
                variant="primary"
                as={Link}
                to={card.URL}
                style={{
                  width: 'auto',
                  backgroundColor: 'rgb(240, 238, 225)',
                  fontWeight: '600',
                  position: 'absolute',
                  bottom: '20px',
                  right: '20px',
                  marginTop: '20px',
                  border: '1px solid black',
                  color: 'black'
                }}
                // onClick={() => setIsExpanded(!isExpanded)}
              >
                {/* {isExpanded ? 'Show less' : 'Show more'} */}
                Saiba mais
              </Button>
              {/* {isExpanded && (
                <Card.Text style={{ marginBottom: '50px' }}>
                  {card.text}
                </Card.Text>
              )} */}
            </Card.Body>
          </Card>
          
        );
      });

      

  return (
    <section className = "py-4 container adjustedMargin">
  
    <div className= "row justify-content-center">
    <h1 style={{display: 'flex', justifyContent: 'center', alignItems: 'center', fontFamily: 'Xilosa'}}>Eventos</h1>

        <div>
        <Link to="/eventos/exposicao" style={{textDecoration:'none', color: 'black'}}>
          <h1 
          style={{
            display: 'flex',
            width: '50%',
            margin: '0 auto',
            justifyContent: 'center',
            padding: '10px 20px',
            border: '2px solid #ccc',
            borderRadius: '5px',
            backgroundColor: 'transparent',
            color: '#333',
            fontSize: '16px',
            textDecoration: 'none',
            transition: 'background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease'
          }}
          onMouseEnter={(e) => e.target.style.backgroundColor = 'rgb(240, 238, 225)'}
          onMouseLeave={(e) => e.target.style.backgroundColor = 'transparent'}>Exposição</h1>  
          </Link>
        </div>
        {cardComponent}
    </div>
    </section>
);
}


export default Events;










