import React, {useEffect, useState} from "react";

import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';	
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import {Link} from 'react-router-dom';

import '../../../App.css';



const FrontCover = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const threshold = 767;

  useEffect(() => {
    // Function to update screenWidth when the window is resized
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Attach event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency array ensures that the effect runs only once on mount



    const centerImage =  {
        display: 'flex',
        // alignItems: 'center',
        maxHeight: '70vh',
        maxWidth: '70vh',
        // margin: '0 auto'
    }

    const sponsorImage = {
      display: 'flex',
      alignItems: 'center',
      maxHeight: '60vh',
      maxWidth: '60vh',
      marginBottom: '10vh',
      // width: '30%',
      height: 'auto',
      width: '30vh',
      // padding: "0 auto"
    }

    
        return (

        <div  className = "background-color-home" style={{minHeight: '100vh', height: 'auto'}}>
          <Container style={{paddingTop: "10vh"}}>
            <div>
          {screenWidth < threshold ? (
<div>      
                      <p className="text-center" style={{ fontFamily: 'Xilosa', color: 'black', fontSize: '30px'}}> 
                            Expedição <br/> Encanta Cordel
                      </p>          
                      <Row style={{paddingBottom: "8vh"}}>
                      <Col style = {{...centerImage, margin: '0 auto', marginTop: '-40px'}}>
                      <img src="./images/logos/cordel.png" alt="Responsive Logo" className="img-fluid mx-auto" />
                      </Col>
                      </Row>
        
                      
                      <Row  style={{paddingBottom: "4vh", display: "flex", alignItems: "center", margin: "0 auto"}}  >
                      <Col md={5} ><img  src = "./images/sponsors/incentivo_PAT.png" alt="Lei de Incentivo a Cultura" style={{ ...sponsorImage, width: '90vw' }} className="img-fluid mx-auto"  /></Col>
                      <Col md={4}><img src = "./images/sponsors/vale_PAT.png" alt="Instituto Cultural Vale" style={{ ...sponsorImage, width: '60vw' }} className="img-fluid mx-auto"/></Col>
                      <Col md={1}><img src = "./images/sponsors/cultura_PAT.png" alt="Ministerio da Cultura Governo Federal Brasil Uniao e Reconstrucao" style={{ ...sponsorImage, width: '80vw' }} className="img-fluid mx-auto"/></Col>
                      <p className="text-center" style={{fontFamily: 'Xilosa', color: 'black', fontSize: '20px'}}>Orgulhosamente feito por  {' '}
          <Link to="https://instagram.com/inglesaustraliano"  target="_blank" rel="noopener noreferrer" style= {{textDecorationColor: 'black'}} >
          <span style={{color: 'black' }}>Marko Kocev</span>
          </Link>
          </p> 
                      </Row>
</div>
          ) : (
            // <p>Show desktop content here</p>
            <div>
          <p className="text-center" style={{ fontFamily: 'Xilosa', color: 'black', fontSize: '30px', marginTop: '-40px'}}> 
            Expedição Encanta Cordel
          </p>             
            <Row >
              <Col style = {{...centerImage, margin: '0 auto', maxHeight: '320px', maxWidth: '320px', marginTop: '-50px'}}>
              <img src="./images/logos/cordel.png" alt="Responsive Logo" className="img-fluid mx-auto" />
              
              </Col>
            </Row>

            <img  src = "./images/sponsors/all_PAT.png" alt="Lei de Incentivo a Cultura"  className="img-fluid mx-auto mb-3"   />
            
            
            <p className="text-center" style={{ fontFamily: 'Xilosa', color: 'black', fontSize: '20px'}}>Orgulhosamente feito por  {' '}
          <Link to="https://instagram.com/inglesaustraliano"  target="_blank" rel="noopener noreferrer" style= {{textDecorationColor: 'black'}} >
          <span style={{ color: 'black' }}>Marko Kocev</span>
          </Link>
          </p> 
          </div>
          )}
          </div>

          </Container>
        </div> 
          );
}


export default FrontCover;