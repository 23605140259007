import React, { useRef, useState, useEffect } from 'react';
import emailjs from '@emailjs/browser';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
// import Link from 'react-router-dom';

export const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  
  const form = useRef();

  

  const sendEmail = (e) => {
    e.preventDefault();

    // Check if required fields are filled
    const userName = form.current.user_name.value.trim();
    const userEmail = form.current.user_email.value.trim();
    const message = form.current.message.value.trim();

    if (!userName || !userEmail || !message) {
      console.log("Please fill in all required fields.");
      return;
    }


    // Proceed with sending the email
    emailjs.sendForm('service_9hvraa4', 'template_xtf9sgg', form.current, 'ye1zMDVJAdSekS2w_')
      .then((result) => {
        console.log(result.text);
        console.log("message sent");
        setSuccessMessage( "Recebemos sua mensagem, responderemos em breve!");
        e.target.reset();
      })
      .catch((error) => {
        console.log(error.text);
      });
  };

  function emailDirectly() {
    window.location.href = "mailto:encantacordel@gmail.com";
  }

  const [successMessage, setSuccessMessage] = useState('');




  return (
    <div className="container adjustedMargin" style={{fontSize: '15px'}}>
      <Row style={{paddingTop: '20px'}}>
        {/* Left div with text */}
        <Col md={6}>
          <div>
            <h2>Entre em contato conosco!</h2>
            <br/>            
            <p>Você é um artista do seguimento do cordel? Você gostaria que suas informações fossem compartilhadas gratuitamente pelo mundo? Preencha o <a href="https://google.com" target="_blank" rel="noreferrer">formulário</a> e compartilhe sua experiência conosco!</p>
            
            <div style={{display:'flex' }}> <Button className="mx-auto img-fluid" style={{backgroundColor: 'rgb(240, 238, 225)', color: 'black', border: '3px solid black'}} href="https://google.com" target="_blank" >formulário</Button></div>
            <div style={{display:'flex'}} >
            <p className="mx-auto" >
              <br/>
              Projeto Expedição Encanta Cordel <br/>
              <i class="fa-regular fa-envelope"></i>  <a href="" onClick={emailDirectly}>encantacordel@gmail.com</a> <br/>
              <i class="fa-brands fa-instagram"></i>  <a href="https://www.instagram.com/expedicaoencantacordel/" target="_empty">@expedicaoencantacordel</a> <br/>
            </p>
            </div>
          </div>
        </Col>

        {/* Right div with a form */}
        <Col md={6}>
          <div >
            <Form ref={form} onSubmit={sendEmail} className="col-10 mx-md-auto mt-5" style={{margin: '0 auto', marginBottom: '40px'}}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Nome</Form.Label>
                <Form.Control type="text" name="user_name" placeholder="Fulano de Tal" required 
                  onInvalid={e => e.target.setCustomValidity("Por favor, escreva seu nome.")}
                  onInput={(e) => e.target.setCustomValidity('')}
                  />
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" name="user_email" placeholder="fulanodetal@exemplo.com" required
                  onInvalid={e => e.target.setCustomValidity("Por favor, insira um endereço de e-mail válido.")}
                  onInput={(e) => e.target.setCustomValidity('')} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <Form.Label>Mensagem</Form.Label>
                <Form.Control name="message" as="textarea" rows={3} required
                  onInvalid={e => e.target.setCustomValidity("Por favor, digite uma mensagem.")}
                  onInput={(e) => e.target.setCustomValidity('')} />
            </Form.Group>

            <Form.Group className = "m-3">
              {successMessage && <p><i class="fa-regular fa-circle-check"></i> &nbsp; &nbsp; {successMessage}</p>}
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <Form.Control style={{color: "black", backgroundColor: "rgb(240, 238, 225)", fontWeight: "600"}} type="submit" value="Mandar"  />
            </Form.Group>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Contact;


